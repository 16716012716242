<template>
  <div class="bg-custom py-3">
    <div class="container-lg">
      <!-- <div class="banner">
      <div class="row">
        <div class="col-4">
          <div class="banner-contents">
            <h1>Pembukaan Akuan Baru</h1>
            <p>
              Memperkenalkan platform terbaharu iaitu Pembukaan Akaun secara Maya (Virtual Account Opening - VAO) menerusi pautan dan aplikasi THiJARI.
            </p>
          </div>
        </div>
      </div>
    </div> -->
      <div class="contents pt-3 mt-1 ">
        <div
          id="carouselExampleCaptions"
          class="carousel slide bg-dark is-mobile rounded overflow-hidden"
          data-bs-ride="carousel"
          style="cursor: pointer"
        >
          <div class="carousel-backdrop"></div>
          <div class="carousel-indicators">
            <button
              v-for="(banner, i) in listOfBanner"
              :key="i"
              type="button"
              :data-bs-target="`#carouselExampleCaptions`"
              :class="i == selectedNext ? 'active' : ''"
              :aria-current="i == 0 ? false : true"
              :aria-label="`Slide ${i}`"
              @click="next(i)"
              :data-bs-slide-to="i"
            ></button>
          </div>
          <div class="carousel-inner">
            <div
              :class="
                i == selectedNext ? 'carousel-item active' : 'carousel-item'
              "
              v-for="(banner, i) in listOfBanner"
              :key="i"
            >
              <img
                v-if="getImgSlider(banner) != null"
                :src="getImgSlider(banner)"
                :class="
                  getImgSlider(banner) != null
                    ? 'd-block w-100'
                    : 'd-block h-100'
                "
                alt="..."
                @click="goto(banner.url)"
              />
              <div class="carousel-caption d-none d-md-block">
                <div class="banner-contents custom-banner-position">
                  <h1>{{ !banner.title ? "" : banner.title }}</h1>
                  <p>{{ !banner.description ? "" : banner.description }}</p>
                </div>
              </div>
            </div>
          </div>
          <!-- <button
          class="carousel-control-prev"
          type="button"
          data-bs-target="#carouselExampleCaptions"
          data-bs-slide="prev"
        >
          <span class="carousel-control-prev-icon" aria-hidden="true"></span>
          <span class="visually-hidden">Previous</span>
        </button>
        <button
          class="carousel-control-next"
          type="button"
          data-bs-target="#carouselExampleCaptions"
          data-bs-slide="next"
        >
          <span class="carousel-control-next-icon" aria-hidden="true"></span>
          <span class="visually-hidden">Next</span>
        </button> -->
        </div>
      </div>

      <div class="contents pt-3 mt-5">
        <!-- <div class="row mx-0" v-if="userData != null">
        <div class="col">
          <div class="alert alert-success" role="alert">
            <h6 class="fw-bolder">
              Selamat Datang, {{ helper.snakeToTitleCase(userData.username) }}
            </h6>
          </div>
        </div>
      </div> -->
        <div
          class="row mx-0"
          v-if="showHeaderBox && currentUser && userCourseCompletions.length > 0"
        >
          <div class="col-12">
            <div class="d-none d-sm-block">
              <h2>Status Pembelajaran Anda</h2>
            </div>
            <div class="d-block d-sm-none">
              <h4>Status Pembelajaran Anda</h4>
            </div>
          </div>
        </div>
        <div class="container-panel mx-0">
          <div class="row mx-0">
            <div class="col-sm-12 col-md-8 col-lg-8 col-xl-9 mb-5">
              <div class="row mb-2" v-if="showHeaderBox && currentUser">
                <div class="col-12">
                  <div class="contents">
                    <div
                      v-for="course in courses"
                      :key="course.id"
                      class="header-box mb-4"
                      :style="getHeaderBoxStatusStyle(course)"
                      @click="gotoCourse(`/user/courses/${course.id}`, course)"
                    >
                      <div class="row mx-0">
                        <div class="col-10">
                          <div class="box-left">
                            <div
                              class="d-flex justify-content-start align-items-center"
                            >
                              <svg
                                width="48"
                                height="49"
                                viewBox="0 0 48 49"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <rect
                                  x="0.5"
                                  y="1"
                                  width="47"
                                  height="47"
                                  rx="7.5"
                                  fill="#E3F3F7"
                                />
                                <path
                                  d="M24 19.5C24 18.4391 23.5786 17.4217 22.8284 16.6716C22.0783 15.9214 21.0609 15.5 20 15.5H14V30.5H21C21.7956 30.5 22.5587 30.8161 23.1213 31.3787C23.6839 31.9413 24 32.7044 24 33.5M24 19.5V33.5M24 19.5C24 18.4391 24.4214 17.4217 25.1716 16.6716C25.9217 15.9214 26.9391 15.5 28 15.5H34V30.5H27C26.2044 30.5 25.4413 30.8161 24.8787 31.3787C24.3161 31.9413 24 32.7044 24 33.5"
                                  stroke="#309CB4"
                                  stroke-width="2"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                                <rect
                                  x="0.5"
                                  y="1"
                                  width="47"
                                  height="47"
                                  rx="7.5"
                                  stroke="#D3E7EB"
                                />
                              </svg>
                              <div class="d-none d-sm-block ms-4">
                                <h2>{{ course.attributes.course_name }}</h2>
                                <h5>
                                  {{ getUserCourseCompletionStatus(course) == 'Selesai' ? getUserTestCompletionStatus(course) : getUserCourseCompletionStatus(course)}}
                                </h5>
                              </div>
                              <div class="d-block d-sm-none ms-4">
                                <h6>{{ course.attributes.course_name }}</h6>
                                <small
                                  >{{ getUserCourseCompletionStatus(course) == 'Selesai' ? getUserTestCompletionStatus(course) : getUserCourseCompletionStatus(course)}}</small
                                >
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-2 d-flex">
                          <div
                            class="d-flex justify-content-end align-items-center w-100"
                          >
                            <button type="button" class="">
                              <svg
                                width="22"
                                height="23"
                                viewBox="0 0 22 23"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M11 15.5L15 11.5M15 11.5L11 7.5M15 11.5H7M21 11.5C21 17.0228 16.5228 21.5 11 21.5C5.47715 21.5 1 17.0228 1 11.5C1 5.97715 5.47715 1.5 11 1.5C16.5228 1.5 21 5.97715 21 11.5Z"
                                  stroke="white"
                                  stroke-width="2"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                              </svg>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="w-100 mt-2">
                      <div class="d-flex justify-content-end">
                        <router-link to="/user/courses" class="text-muted">
                          Lihat semua
                        </router-link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="row mx-0">
                <div class="col-12">
                  <ul class="nav">
                    <li class="nav-item">
                      <a
                        class="nav-link nav-title"
                        :class="{ 'active' : currentTab == 1 }"
                        @click="currentTab = 1"
                        aria-current="page"
                        href="#"
                      >
                        <i class="bi bi-calendar"></i>
                        Program
                      </a>
                    </li>
                    <li class="nav-item">
                      <a
                        class="nav-link nav-title"
                        :class="{ 'active' : currentTab == 2 }"
                        @click="currentTab = 2"
                        href="#"
                      >
                        <i class="bi bi-clipboard-data"></i>
                        Artikel
                      </a>
                    </li>
                    <li class="nav-item">
                      <a
                        class="nav-link nav-title"
                        :class="{ 'active' : currentTab == 3 }"
                        @click="currentTab = 3"
                        href="#"
                      >
                        <i class="bi bi-images"></i>
                        Galeri Gambar
                      </a>
                    </li>
                  </ul>
                </div>
              </div>

              <div v-if="currentTab == 1" class="row g-2 mx-0 mt-3">
                <div class="card program-card">
                  <div class="card-body">
                    <div
                      v-for="(program, i) in listOfPrograms"
                      :key="i"
                      @click="viewStreamPage(program)"
                      class="card-item"
                      style="cursor: pointer"
                    >
                      <div class="row mx-0">
                        <div class="col-10">
                          <div
                            class="d-flex justify-content-start align-items-center"
                          >
                            <svg
                              width="48"
                              height="49"
                              viewBox="0 0 48 49"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <rect
                                x="0.5"
                                y="1"
                                width="47"
                                height="47"
                                rx="7.5"
                                fill="#E3F3F7"
                              />
                              <path
                                d="M16 32C16 31.337 16.2634 30.7011 16.7322 30.2322C17.2011 29.7634 17.837 29.5 18.5 29.5H32M16 32C16 32.663 16.2634 33.2989 16.7322 33.7678C17.2011 34.2366 17.837 34.5 18.5 34.5H32V14.5H18.5C17.837 14.5 17.2011 14.7634 16.7322 15.2322C16.2634 15.7011 16 16.337 16 17V32Z"
                                stroke="#3094B4"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                              <rect
                                x="0.5"
                                y="1"
                                width="47"
                                height="47"
                                rx="7.5"
                                stroke="#C8D9EC"
                              />
                            </svg>
                            <div class="d-block ms-4">
                              <h5 class="fw-bold">
                                {{
                                program && program.attributes
                                  ? program.attributes.course_name
                                  : ""
                                }}
                              </h5>
                              <small class="fw-regularer">
                                {{ getProgramDateTime(program) }}
                              </small>
                            </div>
                          </div>
                        </div>
                        <div class="col-2 d-flex">
                          <div
                            class="d-flex justify-content-end align-items-center w-100"
                          >
                            <div
                              v-if="program.attributes.role_access !== 'public' && !currentUserAccessType"
                            >
                              <span class="success">Log Masuk</span>
                            </div>
                            <button type="button" class="">
                              <svg
                                width="22"
                                height="23"
                                viewBox="0 0 22 23"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M11 15.5L15 11.5M15 11.5L11 7.5M15 11.5H7M21 11.5C21 17.0228 16.5228 21.5 11 21.5C5.47715 21.5 1 17.0228 1 11.5C1 5.97715 5.47715 1.5 11 1.5C16.5228 1.5 21 5.97715 21 11.5Z"
                                  stroke="#3E4C6C"
                                  stroke-width="2"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                              </svg>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div v-if="currentTab == 2" class="row mx-0 mt-3">
                <div
                  class="col-md-12 col-sm-12 mb-3"
                  v-for="(article, i) in listOfArticles"
                  :key="i"
                  @click="viewFullReport(article.id)"
                  style="cursor: pointer"
                >
                  <div class="article-box mb-4">
                    <div class="row mx-0">
                      <div class="col-12 col-md-4 mb-3">
                        <div
                          v-if="getImgArticle(article) != null"
                          class="img-thumbnails"
                          :style="`background-image: url(${getImgArticle(article)})`"
                        ></div>
                        <div v-else class="img-thumbnails"></div>
                      </div>
                      <div class="col-12 col-md-8">
                        <div class="row mx-0 g-0">
                          <div class="col-10">
                            <h5
                              class="fw-bold hover-title"
                              style="cursor: pointer"
                            >
                              {{
                            article && article.attributes
                              ? article.attributes.title
                              : ""
                              }}
                            </h5>
                            <!-- <small class="text-muted fw-regularer text-ecllips">
                              {{
                            article && article.attributes
                              ? article.attributes.body
                              : ""
                          }}
                            </small> -->
                            <small class="text-muted fw-regularer text-ecllips">
                              <div
                                v-if="article && article.attributes"
                                v-html="article.attributes.html_content"
                              ></div>
                            </small>
                          </div>
                          <!-- <div class="col-2">
                            <div class="arrow-link text-end">
                              <img src="./../../assets/ico/arrow-up-right.svg" alt="" />
                            </div>
                          </div> -->
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div v-if="currentTab == 3" class="row mx-0 mt-3">
                <div
                  v-if="listOfGalleryImages && listOfGalleryImages.length > 0"
                  class="row"
                  id="imagesViewer"
                >
                  <div
                    v-for="image in listOfGalleryImages"
                    :key="image.id"
                    class="col-md-4 col-sm-6  col-12 mb-2"
                  >
                    <div>
                      <img
                        v-if="image && image.attributes.file && image.attributes.file.data"
                        :src="image.attributes.file.data.attributes.url"
                        alt="Picture 1"
                        class="img-one"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-sm-12 col-md-4 col-lg-4 col-xl-3">
              <panel-widget />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import qs from "qs";
import moment from "moment";
import API from "./../../utils/API";
import Helper from "./../../utils/helper";
import panelWidget from "../../components/PanelWidget.vue";
import Swal from "sweetalert2";
import haha from '../../assets/img/loader.gif'
import { mapActions, mapGetters } from "vuex";

export default {
  name: "home",
  components: {
    panelWidget,
  },
  data() {
    return {
      windowWidth: window.innerWidth,
      listOfArticles: [],
      listOfPrograms: [],
      listOfBanner: [],
      listOfGalleryImages: [],
      totalPage: 0,
      helper: Helper,
      userData: null,
      selectedNext: 0,
      showHeaderBox: true,
      currentTab: 1, // 1: program, 2: article, 3: galeri
      courses: [],
      userCourseCompletions: [],
      userTestCompletions: []
    };
  },
  watch: {
    windowWidth(newWidth, oldHeight) {
      return newWidth;
    },
    currentTab(){
      if(this.currentTab == 3) { //initiate viewer
        setTimeout(() => {
          this.initViewer()
        }, 200);
      }
    }
  },
  computed: {
    ...mapGetters(["currentUser", "currentUserAccessType"]),
  },
  methods: {
    viewStreamPage(obj) {
      if (obj && obj.attributes && obj.attributes.course_type == "live" && obj.attributes.zoom_id && obj.attributes.zoom_id !== '') {
        this.$router.push({
          path: `/watch-stream-zoom/${obj.id}`,
          meta: { id: obj.id },
        });
      }
      else if (obj && obj.attributes && obj.attributes.course_type == "live") {
        this.$router.push({
          path: `/watch-stream/${obj.id}`,
          meta: { id: obj.id },
        });
      }
      else if (
        obj &&
        obj.attributes &&
        obj.attributes.course_type == "online"
      ) {
        console.log(obj.id);

        if(this.currentUser){
          this.$router.push({
            path: `/user/courses/${obj.id}`,
            meta: { id: obj.id },
          });
        }else {
          if (obj.attributes.role_access !== 'public'){

            Swal.fire({
              title: 'Log Masuk',
              icon: 'warning',
              text: 'Log masuk untuk menyertai kursus ini?',
              showCancelButton: true,
            }).then((result) => {
              if (result.isConfirmed){
                this.$router.push({
                  path: `/auth/login`,
                });
              }
            });
          }else {
            this.$router.push({
            path: `/public-courses/courses/${obj.id}`,
            meta: { id: obj.id },
            });
          }

        }
      }
    },
    next(i) {
      this.selectedNext = i;
    },
    onResize() {
      this.windowWidth = window.innerWidth;
    },
    getImgSlider(obj) {
      if (
        obj &&
        obj.img_file &&
        obj.img_file.data &&
        obj.img_file.data.length > 0
        // obj.attributes.banner[0].img_file.data[0].attributes
        // obj.attributes.banner[0].img_file.data[0].attributes.url
      ) {
        return (
          // "https://tabunghaji-api.inference.asia" +
          obj.img_file.data[0].attributes.url
        );
      } else return null;
      // } else return '../../assets/img/dummy_banner.png';
    },
    getImgProgram(obj) {
      if (
        obj &&
        obj.attributes &&
        obj.attributes.files &&
        obj.attributes.files.data &&
        obj.attributes.files.data.attributes
      ) {
        // console.log(files);
        return (
          // "https://tabunghaji-api.inference.asia" +
          obj.attributes.files.data.attributes.url
        );
      } else {
        return null;
      }
    },
    getImgArticle(obj) {
      if (
        obj &&
        obj.attributes &&
        obj.attributes.featured_img_file &&
        obj.attributes.featured_img_file.data &&
        obj.attributes.featured_img_file.data.attributes
      ) {
        return (
          // "https://tabunghaji-api.inference.asia" +
          obj.attributes.featured_img_file.data.attributes.url
        );
      } else {
        return null;
      }
    },
    viewFullReport(id) {
      this.$router.push({ path: `article/${id}` });
    },
    getDate(date) {
      return moment(date).format("DD MMM YYYY");
    },
    getProgramDateTime(program){
      let dateStr = ''
      if(program && program.attributes && program.attributes.start_datetime){
        dateStr = moment(program.attributes.start_datetime).format("DD MMM YYYY");
        if(program.attributes.end_datetime && moment(program.attributes.start_datetime).format("DD MMM YYYY") != moment(program.attributes.end_datetime).format("DD MMM YYYY")){
          dateStr += ` - ${moment(program.attributes.end_datetime).format("DD MMM YYYY")}`;
        }
      }

      return dateStr
    },
    getHomePageBanner() {
      let params = "";
      let url = new URLSearchParams(params);
      url.set("populate", "%2A");
      API.get(
        `homepage?populate[banner][populate][0]=img_file&${url.toString()}`
      ).then((res) => {
        const mobileBanners = res.data.data.attributes.banner.filter(banner => banner.title !== 'mobile');
        if (mobileBanners.length > 0) {
          this.listOfBanner = mobileBanners; // Assign the filtered banners to the array
        }
      });
    },
    getProgram() {
      let userAccessTypesFilter
      if(!this.currentUserAccessType) userAccessTypesFilter = { name: { $eq: 'BH' } }
      else userAccessTypesFilter = { id: { $eq: this.currentUserAccessType.id } }

      const queryParams = qs.stringify({
        filters: {
          user_access_types: userAccessTypesFilter,
          is_active: {
            $eq: true
          }
        }
      }, {
        encodeValuesOnly: true
      })
      let url =
        `courses?${queryParams}`;

      API.get(url).then((res) => {
        this.listOfPrograms = res.data.data;
      });
    },
    getArticle() {
      API.get(
        "articles?populate=%2A&pagination[pageSize]=6&filters[is_active][$eq]=true"
      ).then((res) => {
        this.listOfArticles = res.data.data;
        this.totalPage = res.data.meta.pagination.total / 6;
      });
    },
    getGalleryImages() {
      API.get(
        "gallery-files?populate=%2A&sort[0]=updatedAt%3Adesc&pagination[limit]=50&filters[type][$eq]=image-gallery"
      ).then((res) => {
        this.listOfGalleryImages = res.data.data;
      });
    },
    initUserCourses(){
      return new Promise(async (resolve, reject) => {
        let userAccessTypesFilter
        if(!this.currentUserAccessType) userAccessTypesFilter = { name: { $eq: 'public' } }
        else userAccessTypesFilter = { id: { $eq: this.currentUserAccessType.id } }

        //if pekta not approved yet, then just show public course
        if(this.currentUser && this.currentUser.role && this.currentUser.role.name == 'Pekta'){
          const pektaRes = await API.get(`pektas?filters[user]=${this.currentUser.id}&populate[0]=pekta_applications`);
          if (pektaRes.data.data && pektaRes.data.data.length > 0){
            const pektaApps = pektaRes.data.data[0].attributes.pekta_applications
            if(pektaApps.data && pektaApps.data.length > 0 && pektaApps.data[0].attributes.status !== 'approved')
              userAccessTypesFilter = { name: { $eq: 'public' } }
          }
        }

        const queryParams = qs.stringify({
          filters: {
            user_access_types: userAccessTypesFilter,
            is_active: {
              $eq: true
            },
            course_type: {
              $eq: 'online'
            }
          }
        }, {
          encodeValuesOnly: true
        })
        let url =
          `courses?${queryParams}`;

        const res = await API.get(url);

        // const res = await API.get(
        //   "courses?filters[course_type]=online&populate=%2A"
        // );

        this.courses = res.data.data;

        //sort courses
        this.courses = this.courses.sort((itemA, itemB) => {
          let x = itemA.attributes.course_name.toLowerCase();
          let y = itemB.attributes.course_name.toLowerCase();
          if (x < y) {
            return -1;
          }
          if (x > y) {
            return 1;
          }
          return 0;
        });

        if(this.currentUser){ //show courseCompletion
          const res02 = await API.get(
            `users/${this.currentUser.id}?populate[user_course_completions][populate][course]&populate[courses]&populate[course_mark][populate][course]`
          );

          this.userCourseCompletions = res02.data.user_course_completions;
          this.userTestCompletions = res02.data.course_mark;
          //filter only get the course that is not null.
          this.userCourseCompletions = this.userCourseCompletions.filter(i => i.course !== null)

          // console.log('course name : ', res02.data.user_course_completions[0].course.course_name)
          // console.log('course name : ', res02.data.user_course_completions[0].completion_percentage)
        }

        resolve()
      })
    },
    getUserCourseCompletionStatus(_course){
      let foundCourse = this.userCourseCompletions.find(i => i.course.id == _course.id)

      if(!foundCourse) return 'Belum Bermula'
      else if(foundCourse.completion_percentage >= 0 && foundCourse.completion_percentage < 100) return 'Dalam Proses'
      else if(foundCourse.completion_percentage == 100) return 'Selesai'
    },
    getUserTestCompletionStatus(_course){
      let foundTest = this.userTestCompletions.find(i => i.course.id == _course.id)

      if(!foundTest) return 'Sila Mula Ujian'
      else return 'Selesai'
    },
    getHeaderBoxStatusStyle(_course){
      let foundCourse = this.userCourseCompletions.find(i => i.course.id == _course.id)

      if(!foundCourse) return 'background: linear-gradient(277.96deg, #4441C8 -16.26%, #67A4FE 110.97%);'
      else if(foundCourse.completion_percentage >= 0 && foundCourse.completion_percentage < 100) return 'background: linear-gradient(277.96deg, #ffba08 -16.26%, #faa307 110.97%);'
      else if(foundCourse.completion_percentage == 100) return 'background: linear-gradient(277.96deg, rgb(89 182 57) -16.26%, rgb(108 193 85) 110.97%);;'
    },
    goto(_path) {
      // if(!_path.includes('http'))
      //   _path = 'https://' + _path
      if (_path.trim() != "") window.open(_path, "_self").focus();
    },
    async gotoCourse(_path, _course){
      await this.addNewCourseProgress(_course);
      this.$router.push(_path);
    },
    async addNewCourseProgress(_course) {
      let found =
        this.userCourseCompletions.length > 0
          ? this.userCourseCompletions.find((i) => i.course.id == _course.id)
          : null;
      if (!found) {
        this.userCourseCompletions.push({
          course: _course,
          completion_percentage: 0,
        });

        await API.put(`users/${this.currentUser.id}`, {
          courses: this.courses,
          user_course_completions: this.userCourseCompletions,
        });
      }
    },
    async initViewer(){
      const gallery = new Viewer(document.getElementById('imagesViewer'));
    }
  },
  async mounted() {
    const dateOne = moment();
    const dateTwo = moment([2022, 6, 9]);
    let result = dateTwo.diff(dateOne, "days");
    this.countdownWukuf = result;

    this.$nextTick(() => {
      window.addEventListener("resize", this.onResize);
    });
    const user = JSON.parse(localStorage.getItem("user"));
    if (user != undefined && user != null) {
      this.userData = user;
    }

    await this.getHomePageBanner();
    await this.getArticle();
    await this.getProgram();
    await this.initUserCourses();
    await this.getGalleryImages();
    // await this.initViewer();
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.onResize);
  },
};
</script>

<style lang="scss" scoped>
@import "./../../assets/scss/color.scss";

.bg-custom {
  background: url("./../../assets/img/bg-all.jpg");
  background-size: cover;
}

.container-panel {
  position: relative;
  // display: flex;
  justify-content: space-between;

  @media (max-width: 450px) {
    display: block;
  }

  .panel-right {
    max-width: 300px;
    padding-left: 15px;

    @media (max-width: 450px) {
      width: 100% !important;
      padding-left: 15px;
      padding-right: 15px;
      padding-bottom: 15px;
      margin-bottom: 20px;
    }
  }

  .panel-left {
    width: calc(100vw - 300px);
    overflow: hidden;
    // padding-left: 15px;
    padding-right: 15px;

    @media (max-width: 450px) {
      width: 100% !important;
    }
  }
}

.nav {
  .nav-item {
    .nav-link {
      &.nav-title {
        font-size: 1.2rem;

        &.active {
          color: #000000;
        }
      }

      padding: 10px 40px 20px 0px;
      color: #6c757d;
    }
  }
}

.col-md-2 {
  @media screen and (min-width: 450px) and (max-width: 820px) {
    width: 50% !important;
  }

  @media screen and (min-width: 820px) and (max-width: 1400px) {
    width: 33.333333% !important;
  }
}
.col-md-4 {
  @media screen and (min-width: 450px) and (max-width: 820px) {
    width: 50% !important;
  }
}

.header-box {
  width: 100%;
  // height: 285px;
  padding: 0px 15px;
  border-radius: 7px;
  // background-color: $green;
  background: linear-gradient(277.96deg, #4441C8 -16.26%, #67A4FE 110.97%);
  color: $white;

  &.test-bg {
    background: linear-gradient(
        84.94deg,
        #373b44 32.34%,
        rgba(55, 59, 68, 0) 129.79%
      ),
      url("./../../assets/img/pen01.jpg");
    background-size: cover;

    &.pass-test {
      background: linear-gradient(
          72.18deg,
          #ffd600 29.54%,
          rgba(255, 153, 0, 0) 222.39%
        ),
        url("./../../assets/img/pen01.jpg");
      background-size: cover;
      color: #000;
    }
  }

  .box-left {
    padding: 18px 15px;
  }
}

.hover-title {
  &:hover {
    text-decoration: underline !important;
  }
}
.is-mobile {
  // min-height: 324.2px;
  max-height: 344.2px;

  @media (max-width: 500px) {
    min-height: auto;
  }
}

.banner {
  width: 100%;
  height: 319px;
  background: url("./../../assets/img/dummy_banner.png");
  background-size: cover;
  padding: 80px 71px;

  .banner-contents {
    color: $white;

    p {
      font-size: 1.1rem;
    }
  }
}

.hide-scrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */

  &::-webkit-scrollbar {
    display: none;
  }
}

.contents {
  h6 {
    margin: 0px;
  }

  .program-box {
    width: 198px;
    height: 289px;
    border-radius: 5px;
    line-height: 1.5rem;

    .program-box-body {
      color: $white;
      padding: 15px;

      small {
        display: block;
        margin: 3px 0px;
      }

      .btn {
        font-size: 0.8rem;
        padding: 2px 8px !important;
        border-radius: 16px !important;
      }
    }
  }

  .bg-thumb-default {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    height: 225px !important;
    background-image: url("../../assets/img/dummy_box.png");
  }

  .article-box {
    // max-height: 700px;
    overflow: hidden;

    .img-thumbnails {
      position: relative;
      display: block;
      height: 220px;
      width: 100%;
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      background-image: url("../../assets/img/no-img.png");
      border-radius: 10px;
    }

    img {
      height: 240px;
      width: 100%;
    }
    p {
      color: $gray-reqular;
    }
    .arrow-link {
      cursor: pointer;
      img {
        height: 25px;
        width: 25px;
      }
    }
  }
}

.carousel-backdrop {
  background: #8ac558;
  width: 100%;
  height: 100%;
  position: absolute;
}

.carousel-caption {
  bottom: 2.5rem;
  text-align: left;
  left: 4%;
}

.text-ecllips {
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 7;
  -webkit-box-orient: vertical;
  display: -webkit-box;
}

.rect-obj {
  width: 12px;
  height: 42px;
  background-color: $green;
}

.card {
  &.program-card{
    .card-body {
      .card-item {
        padding: 10px 0px;
        margin: 5px 0px;

        &:not(:last-child) {
          border-bottom: 1px solid #E7EDF2;
        }
      }
    }
  }
}

#imagesViewer {
  .img-one {
    object-fit: cover;
    width: 300px;
    height: 300px;
    cursor: pointer;

    @media (max-width: 1399px) {
      width: 250px;
      height: 250px;
    }

    @media (max-width: 1199px) {
      width: 185px;
      height: 185px;
    }

    @media (max-width: 820px) {
      width: 230px;
      height: 230px;
    }

    @media (max-width: 767px) {
      width: 300px;
      height: 300px;
    }
  }
}

span {
	background-color: #656565;
	color: #ffffff;
	font-size: 0.7em;
	font-weight: 400;
	line-height: 1;
	padding: .3em .6em;
	text-align: center;
	vertical-align: baseline;
  margin-right: 3px;
  border-radius: 3px;
}

span.success {
	background-color: #0bc7d5;
}
</style>
