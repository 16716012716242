<template>
  <div>
    <div class="card wukuf-container overflow-hidden">
      <div class="card-body p-0">
        <div class="row m-0">
          <div class="col-12">
            <div class="d-flex justify-content-center align-items-center h-100">
              <div class="pt-3 text-center">
                <h4 class="text-white m-0 fw-bold">Wuquf</h4>
              </div>
            </div>
          </div>
        </div>
        <div class="row m-0">
          <div class="col-6">
            <div class="d-flex justify-content-end align-items-center h-100">
              <div class="pt-2 pb-3">
                <h1 class="text-white m-0 fw-bolder">{{ countdownWukuf }}</h1>
              </div>
            </div>
          </div>
          <div class="col-6">
            <div class="d-flex justify-content-start align-items-center h-100">
              <div class="pt-2 pb-3">
                <h5 class="text-white m-0 fw-bolder">Hari Lagi</h5>
                <p class="small extra-small-2 text-white m-0">5 Jun 2025</p>
                <p class="small extra-small-2 text-white m-0">
                  9 Zulhijjah 1446H
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      class="card mt-2 overflow-hidden"
      style="background-color: rgb(255 255 255)"
    >
      <!-- <div class="card-body" style="min-width: 282px;"> -->
      <div class="card-body">
        <div class="title">
          <div class="row">
            <div class="col-6">
              <small class="mb-1">Kalendar</small>
            </div>
            <div class="col-6 text-end">
              <div
                style="cursor:pointer;"
                @click="toggleCalendarFull = !toggleCalendarFull"
              >
                <svg
                  width="18"
                  height="20"
                  viewBox="0 0 18 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M12.3333 1.6665V4.99984M5.66667 1.6665V4.99984M1.5 8.33317H16.5M3.16667 3.33317H14.8333C15.7538 3.33317 16.5 4.07936 16.5 4.99984V16.6665C16.5 17.587 15.7538 18.3332 14.8333 18.3332H3.16667C2.24619 18.3332 1.5 17.587 1.5 16.6665V4.99984C1.5 4.07936 2.24619 3.33317 3.16667 3.33317Z"
                    stroke="#344054"
                    stroke-width="1.67"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
            </div>
          </div>
        </div>
        <div class="d-flex justify-content-center">
          <v-date-picker
            v-if="toggleCalendarFull"
            style="background-color: rgb(255 255 255)"
            class="border-0"
            :is-required="true"
            locale="ms-MY"
            v-model="date"
            :attributes="[
              { highlight: 'gray', dates: [new Date()] },
              { dot: 'red', dates: coursesDot },
              { dot: 'blue', dates: publicHolidayDot },
            ]"
          />
        </div>
        <div class="mt-3 mb-2">
          <div class="mb-2">
            <div
              class="
                title
                border-bottom
                mb-2
                d-flex
                justify-content-between
                align-items-center
              "
            >
              <small class="mb-1">Kursus</small>
              <small class="mb-1 fw-bold text-muted">
                {{ convertDate(date) }}
              </small>
            </div>
            <template v-if="coursesThisDay.length > 0">
              <!-- <div
                class="
                  d-flex
                  justify-content-start
                  align-items-center
                  bg-light
                  px-2
                  mb-2
                  rounded
                  shadow-sm
                "
                style="font-size: 0.7rem"
                v-if="
                  coursesThisDay.some((course) => course.course_type == 'live')
                "
              >
                <i
                  class="fas fa-circle text-primary me-2"
                  style="font-size: 0.5em"
                />
                Siaran Langsung
              </div> -->
              <div
                class="d-flex justify-content-start align-items-center"
                v-for="course in coursesThisDay"
                :key="`course-${course.id}`"
              >
                <p class="fw-regular" style="width: 40%">
                  {{ course.time }}
                </p>
                <p
                  class="fw-regular text-end text-nowrap text-truncate lh-sm"
                  style="width: 60%"
                  :title="course.course_name"
                >
                  <i
                    class="fas fa-circle me-1 text-danger align-middle"
                    style="font-size: 0.5em"
                  />
                  {{ course.course_name }}
                </p>
              </div>
            </template>
            <div class="text-center" v-else>
              <small class="fst-italic text-muted">
                Tiada kursus hari ini
              </small>
            </div>
          </div>
          <div v-if="publicHolidays.length > 0">
            <div
              class="
                title
                border-bottom
                mb-2
                d-flex
                justify-content-between
                align-items-center
              "
            >
              <small class="mb-1">Cuti Umum</small>
            </div>
            <template>
              <div
                class="d-flex justify-content-between"
                v-for="(holiday, index) in publicHolidays"
                :key="index"
              >
                <small class="fst-italic">
                  {{ holiday.name }}
                </small>
              </div>
            </template>
          </div>
          <div class="mt-3" v-if="toggleCalendarFull">
            <div
              class="
                title
                border-bottom
                mb-2
                d-flex
                justify-content-between
                align-items-center
              "
            >
              <small class="mb-1">Petunjuk</small>
            </div>
            <template>
              <div class="d-flex justify-content-between">
                <small class="fst-italic">
                  <i
                    class="fas fa-circle me-1 text-primary align-middle"
                    style="font-size: 0.5em"
                  />
                  Cuti Umum
                </small>
              </div>
              <div class="d-flex justify-content-between">
                <small class="fst-italic">
                  <i
                    class="fas fa-circle me-1 align-middle"
                    style="font-size: 0.5em;color: red;"
                  />
                  Kursus
                </small>
              </div>
            </template>
          </div>
        </div>
      </div>
    </div>

    <div class="card solat-container mt-2 overflow-hidden">
      <div class="card-body text-white">
        <div class="title pb-2 text-start">
          <small>Waktu Solat</small>
        </div>
        <div class="sub-contents">
          <div class="mt-2 mb-3">
            <div class="d-flex justify-content-start align-items-center">
              <span
                :class="{ 'fw-bolder text-decoration-underline' : selectedPrayerCity == 'kl' }"
                style="font-size:14px"
                @click="selectedPrayerCity = 'kl'"
                role="button"
                >Kuala Lumpur</span
              >
              <span
                class="ms-3"
                :class="{ 'fw-bold text-decoration-underline' : selectedPrayerCity == 'makkah' }"
                style="font-size:14px"
                @click="selectedPrayerCity = 'makkah'"
                role="button"
                >Makkah</span
              >
              <span
                class="ms-3"
                :class="{ 'fw-bold text-decoration-underline' : selectedPrayerCity == 'madinah' }"
                style="font-size:14px"
                @click="selectedPrayerCity = 'madinah'"
                role="button"
                >Madinah</span
              >
            </div>
          </div>
          <div
            class="d-flex mt-2 mb-2 justify-content-between"
            v-if="selectedPrayerCity == 'kl'"
          >
            <div class="mx-4">
              <p class="fw-regular">Subuh</p>
              <p class="fw-regular">Syuruk</p>
              <p class="fw-regular">Zohor</p>
              <p class="fw-regular">Asar</p>
              <p class="fw-regular">Maghrib</p>
              <p class="fw-regular">Isyak</p>
            </div>

            <div class="mx-4">
              <p class="fw-regular">
                {{
                  prayerKLData && prayerKLData.timings
                    ? prayerKLData.timings.Fajr
                    : "--:-- --"
                }}
              </p>
              <p class="fw-regular">
                {{
                  prayerKLData && prayerKLData.timings
                    ? prayerKLData.timings.Sunrise
                    : "--:-- --"
                }}
              </p>
              <p class="fw-regular">
                {{
                  prayerKLData && prayerKLData.timings
                    ? prayerKLData.timings.Dhuhr
                    : "--:-- --"
                }}
              </p>
              <p class="fw-regular">
                {{
                  prayerKLData && prayerKLData.timings
                    ? prayerKLData.timings.Asr
                    : "--:-- --"
                }}
              </p>
              <p class="fw-regular">
                {{
                  prayerKLData && prayerKLData.timings
                    ? prayerKLData.timings.Maghrib
                    : "--:-- --"
                }}
              </p>
              <p class="fw-regular">
                {{
                  prayerKLData && prayerKLData.timings
                    ? prayerKLData.timings.Isha
                    : "--:-- --"
                }}
              </p>
            </div>
          </div>
          <div
            class="d-flex mt-2 mb-2 justify-content-between"
            v-else-if="selectedPrayerCity == 'makkah'"
          >
            <div class="mx-4">
              <p class="fw-regular">Subuh</p>
              <p class="fw-regular">Syuruk</p>
              <p class="fw-regular">Zohor</p>
              <p class="fw-regular">Asar</p>
              <p class="fw-regular">Maghrib</p>
              <p class="fw-regular">Isyak</p>
            </div>

            <div class="mx-4">
              <p class="fw-regular">
                {{
                    prayerMCData && prayerMCData.timings
                      ? convertTime(prayerMCData.timings.Fajr, 'Asia/Riyadh')
                      : "--:-- --"
                }}
              </p>
              <p class="fw-regular">
                {{
                  prayerMCData && prayerMCData.timings
                    ? convertTime(prayerMCData.timings.Sunrise, 'Asia/Riyadh')
                    : "--:-- --"
                }}
              </p>
              <p class="fw-regular">
                {{
                  prayerMCData && prayerMCData.timings
                    ? convertTime(prayerMCData.timings.Dhuhr, 'Asia/Riyadh')
                    : "--:-- --"
                }}
              </p>
              <p class="fw-regular">
                {{
                  prayerMCData && prayerMCData.timings
                    ? convertTime(prayerMCData.timings.Asr, 'Asia/Riyadh')
                    : "--:-- --"
                }}
              </p>
              <p class="fw-regular">
                {{
                  prayerMCData && prayerMCData.timings
                    ? convertTime(prayerMCData.timings.Maghrib, 'Asia/Riyadh')
                    : "--:-- --"
                }}
              </p>
              <p class="fw-regular">
                {{
                  prayerMCData && prayerMCData.timings
                    ? convertTime(prayerMCData.timings.Isha, 'Asia/Riyadh')
                    : "--:-- --"
                }}
              </p>
            </div>
          </div>
          <div
            class="d-flex mt-2 mb-2 justify-content-between"
            v-else-if="selectedPrayerCity == 'madinah'"
          >
            <div class="mx-4">
              <p class="fw-regular">Subuh</p>
              <p class="fw-regular">Syuruk</p>
              <p class="fw-regular">Zohor</p>
              <p class="fw-regular">Asar</p>
              <p class="fw-regular">Maghrib</p>
              <p class="fw-regular">Isyak</p>
            </div>

            <div class="mx-4">
              <p class="fw-regular">
                {{
                    prayerMDData && prayerMDData.timings
                      ? convertTime(prayerMDData.timings.Fajr, 'Asia/Riyadh')
                      : "--:-- --"
                }}
              </p>
              <p class="fw-regular">
                {{
                  prayerMDData && prayerMDData.timings
                    ? convertTime(prayerMDData.timings.Sunrise, 'Asia/Riyadh')
                    : "--:-- --"
                }}
              </p>
              <p class="fw-regular">
                {{
                  prayerMDData && prayerMDData.timings
                    ? convertTime(prayerMDData.timings.Dhuhr, 'Asia/Riyadh')
                    : "--:-- --"
                }}
              </p>
              <p class="fw-regular">
                {{
                  prayerMDData && prayerMDData.timings
                    ? convertTime(prayerMDData.timings.Asr, 'Asia/Riyadh')
                    : "--:-- --"
                }}
              </p>
              <p class="fw-regular">
                {{
                  prayerMDData && prayerMDData.timings
                    ? convertTime(prayerMDData.timings.Maghrib, 'Asia/Riyadh')
                    : "--:-- --"
                }}
              </p>
              <p class="fw-regular">
                {{
                  prayerMDData && prayerMDData.timings
                    ? convertTime(prayerMDData.timings.Isha, 'Asia/Riyadh')
                    : "--:-- --"
                }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="card mt-2 border-0 weather-container shadow-sm">
      <div class="card-body">
        <div class="d-flex justify-content-start align-items-center">
          <h6 class="text-white">Info Cuaca</h6>
        </div>
        <div class="d-flex justify-content-start align-items-center mt-2">
          <div class="img-weather-box">
            <!-- <img
              :src="getImg(weatherData[0], `${arr[0].country},${arr[0].city}`)"
              alt=""
            /> -->
            <img
              src="//ssl.gstatic.com/onebox/weather/64/sunny_s_cloudy.png"
              alt=""
            />
          </div>
          <div class="mt-1">
            <h4 class="text-white mb-0 fw-bolder">
              <!-- {{
                calcTemp(weatherData[0], `${arr[0].country},${arr[0].city}`)
              }}°C -->
              27°C
            </h4>
            <small class="text-white fw-regular">Kuala Lumpur</small>
          </div>
        </div>
        <div class="d-flex justify-content-start align-items-center mt-2">
          <div class="img-weather-box">
            <!-- <img
              :src="getImg(weatherData[1], `${arr[1].country},${arr[1].city}`)"
              alt=""
            /> -->
            <img
              src="//ssl.gstatic.com/onebox/weather/64/partly_cloudy.png"
              alt=""
            />
          </div>
          <div class="mt-1">
            <h4 class="text-white mb-0 fw-bolder">
              <!-- {{
                calcTemp(weatherData[1], `${arr[1].country},${arr[1].city}`)
              }}°C -->
              38°C
            </h4>
            <small class="text-white fw-regular">Makkah</small>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import API from "@/utils/API";
import { mapGetters } from "vuex";
import axios from "axios";
import moment from "moment";
import moment_timezone from 'moment-timezone'
import clear_night from "../assets/ico/clear-night.png";
import overcast from "../assets/ico/overcast.png";
import partialy_cloud from "../assets/ico/partialy-cloud.png";
import partly_cloudy_night from "../assets/ico/partly-cloudy-night.png";
import rain from "../assets/ico/raining.png";
import partly_cloudy_day from "../assets/ico/partly-cloudy-day.png";
import clear_day from "../assets/ico/clear-day.png";

export default {
  name: "panel-left",
  data() {
    return {
      date: new Date(),
      weatherData: [],
      publicHoliday: [],
      courses: [],
      arr: [
        {
          country: "Malaysia",
          city: "Kuala Lumpur",
          coord: { lat: "3.144674081795919", lng: "101.68732990885877" },
        },
        {
          country: "Mekah",
          city: "Al Haram",
          coord: { lat: "21.427647983529564", lng: "39.825250243142285" },
        },
      ],
      selectedPrayerCity: 'kl', // or mekah
      prayerKLData: [],
      prayerMCData: [],
      prayerMDData: [],
      selectedData: moment().format("DD") - 1,
      toggleCalendarFull: false,
    };
  },
  computed: {
    ...mapGetters(["currentUser"]),
    countdownWukuf() {
      const dateOne = moment();
      const dateTwo = moment([2025, 6, 5]);
      let result = dateTwo.diff(dateOne, "days");
console.log(dateTwo)
      return result;
    },
    publicHolidays() {
      const newDate = moment(this.date).format("YYYY-MM-DD");
      return this.publicHoliday.filter((x) => x.date.iso == newDate);
    },
    coursesDot() {
      let arrDates = []
      this.courses.forEach(item => {
        if(item.startDate && item.endDate){
          console.log('start date : ', item.startDate)
          console.log('end date : ', item.endDate)
          for(var arr=[],dt=new Date(item.startDate); dt<=new Date(item.endDate); dt.setDate(dt.getDate()+1)){
              arrDates.push(new Date(dt));
          }
        }
      })
      return arrDates

      // return this.courses
      //   .map((course) => {
      //     return course.startDate
      //   })
      //   .filter(
      //     (value, index, self) => index === self.findIndex((t) => t === value)
      //   );
    },
    publicHolidayDot() {
      let monthName = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ];

      return this.publicHoliday
        .filter((x) => x.date.datetime != undefined)
        .map(
          (holiday) =>
            `${holiday.date.datetime.day} ${
              monthName[parseInt(holiday.date.datetime.month - 1)]
            } ${holiday.date.datetime.year}`
        );
    },
    coursesThisDay() {
      return this.courses.filter(
        (course) => {
          return moment(course.startDate, 'DD MMM YYYY').isSame(moment(this.date).format('DD MMM YYYY'))
                || (moment(moment(this.date).format('DD MMM YYYY')).isAfter(course.startDate, 'DD MMM YYYY')
                    && moment(moment(this.date).format('DD MMM YYYY')).isBefore(course.endDate, 'DD MMM YYYY'))
                || moment(course.endDate, 'DD MMM YYYY').isSame(moment(this.date).format('DD MMM YYYY'))

          // return course.date == this.convertDate(this.date)
        }
      );
    },
  },
  methods: {
    getCourses() {
      return new Promise(async (resolve, reject) => {
        try {
          let url =
            "courses?filters[is_active]=true&filters[role_access][$in]=public";

          if (this.currentUser && this.currentUser.role.name) {
            url += `&filters[role_access][$in]=${this.currentUser.role.name.toLowerCase()}`;
          }

          const { data, error } = await API.get(url);

          if (error) return reject();
          this.courses = data.data
            .filter((course) => course.attributes.start_datetime)
            .map((course) => {
              return {
                id: course.id,
                course_name: course.attributes.course_name,
                course_type: course.attributes.course_type,
                description: course.attributes.description,
                date: this.convertDate(course.attributes.start_datetime),
                time: this.convertTime(course.attributes.start_datetime),
                calenderDate: this.convertCalenderDate(
                  course.attributes.start_datetime
                ),
                startDate: this.convertDate(course.attributes.start_datetime),
                endDate: this.convertDate(course.attributes.end_datetime)
              };
            });

          return resolve();
        } catch (error) {
          return reject();
        }
      });
    },
    getImg(data, _name) {
      let icon;
      if (data != undefined && data != null) {
        this.arr.forEach((x) => {
          icon = data.locations[_name].currentConditions.icon;
          return;
        });
      }
      if (icon != undefined) {
        if (icon == "clear-night") {
          return clear_night;
        } else if (icon == "partly-cloudy-night") {
          return partly_cloudy_night;
        } else if (icon.includes("rain")) {
          return rain;
        } else if (icon == "partialy-cloud") {
          return partialy_cloud;
        } else if (icon.includes("overcast")) {
          return overcast;
        } else if (icon == "clear-day") {
          return clear_day;
        } else if (icon == "partly-cloudy-day") {
          return partly_cloudy_day;
        } else {
          return clear_day;
        }
      }
    },
    convertTime(time, timezone) {
      if(time && timezone)
        return moment_timezone(time).tz(timezone).format("h:mm A")
      else if(time && !timezone){
        return moment(time).utc().format("h:mm A");
      }
      else return null
    },
    convertDate(datetime) {
      return datetime ? moment(datetime).locale("ms-My").format("DD MMM YYYY") : null;
    },
    convertCalenderDate(datetime) {
      return datetime ? moment(datetime).format("DD MMM YYYY") : null;
    },
    calcTemp(data, _name) {
      let valNum = 0;
      if (data != undefined && data != null) {
        this.arr.forEach((x) => {
          valNum = data.locations[_name].currentConditions.temp;
          return;
        });

        return Math.floor((valNum - 32) / 1.8);
      }
    },
    async getPrayerTime() {
      axios.get('https://mpt.i906.my/mpt.json?filter=1&code=wlp-0').then((retVal) => {
        let newItem = retVal.data.response.times;

        const newDate = newItem.map((time) => {
          return moment.unix(time).format("h:mm A");
          // return moment.unix(time).tz('Asia/Kuala_Lumpur').format("h:mm A");
          // return moment_timezone(time).tz('Asia/Kuala_Lumpur').format("h:mm A");
        });

        let timings = {
          Fajr: newDate[0],
          Sunrise: newDate[1],
          Dhuhr: newDate[2],
          Asr: newDate[3],
          Maghrib: newDate[4],
          Isha: newDate[5],
        };
        this.prayerKLData = { timings };
      });

      axios.get(`https://api.aladhan.com/v1/timingsByAddress/${moment().format("DD-MM-YYYY")}?address=Makkah%2C%20Saudi%20Arabia&method=2&latitudeAdjustmentMethod=3&school=0&_=1647244197916&method=8&iso8601=true`).then((res) => {
        this.prayerMCData = res.data.data;
      });

      axios.get(`https://api.aladhan.com/v1/timingsByAddress/${moment().format("DD-MM-YYYY")}?address=Medina%2C%20Saudi%20Arabia&method=2&latitudeAdjustmentMethod=3&school=0&_=1647244197916&method=8&iso8601=true`).then((res) => {
        this.prayerMDData = res.data.data;
      });

      //previously using ${moment().tz('Asia/Kuala_Lumpur').format("DD-MM-YYYY")}

    },
    async getWeather() {
      const arrayWeather = [];

      for (let i = 0; i < this.arr.length; i++) {
        const opts = {
          method: "get",
          url: `https://weather.visualcrossing.com/VisualCrossingWebServices/rest/services/weatherdata/forecast?locations=${this.arr[i].country},${this.arr[i].city}&aggregateHours=24&unitGroup=us&shortColumnNames=false&contentType=json&key=D3XHBTYK4DF7U8H5TJMELJFXB`,
        };

        await axios(opts).then((res) => {
          arrayWeather.push(res.data);
        });
      }
      this.weatherData = arrayWeather;
    },
    getCalender() {
      return new Promise(async (resolve, reject) => {
        try {
          let url = "custom/list-public-holidays";

          const { data, error } = await API.get(url);
          this.publicHoliday = data.public_holidays;
          if (error) return reject();
          return resolve();
        } catch (error) {
          return reject();
        }
      });
    },
  },
  async mounted() {
    // await this.getWeather();
    await this.getPrayerTime();
    await this.getCourses();
    await this.getCalender();
  },
};
</script>

<style lang="scss">
@import "./../assets/scss/color.scss";
@import "./../assets/scss/main.scss";

.img-weather-box {
  width: 61.33px;
  height: 61.33px;

  img {
    width: 100%;
    padding-right: 15px;
  }
}

.card {
  &.wukuf-container {
    background-color: $turquoise;
  }

  &.solat-container {
    background-color: $dark-blue;

    .sub-contents {
      background-color: #08275e;
      padding: 10px;
      border-radius: 10px;
    }
  }

  &.weather-container {
    background-color: #212529;
  }
}
</style>
